import {CssBaseline} from '@mui/material';
import ReactDOM from 'react-dom/client';

import {createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';

import '@refinio/one.core/lib/system/load-browser.js';

import {objectEvents} from '@refinio/one.models/lib/misc/ObjectEventDispatcher.js';

import '@refinio/one.core/lib/system/load-browser.js';

import '@refinio/one.core/lib/system/browser/crypto-helpers.js';
import '@refinio/one.core/lib/system/browser/crypto-scrypt.js';
import '@refinio/one.core/lib/system/browser/settings-store.js';
import '@refinio/one.core/lib/system/browser/storage-base.js';
import '@refinio/one.core/lib/system/browser/storage-base-delete-file.js';
import '@refinio/one.core/lib/system/browser/storage-streams.js';

// Imported for the side effect of loading these types
import '@refinio/one.models/lib/recipes/Certificates/AffirmationCertificate.js';
import '@refinio/one.models/lib/recipes/Certificates/TrustKeysCertificate.js';
import '@refinio/one.models/lib/recipes/Certificates/RightToDeclareTrustedKeysForEverybodyCertificate.js';
import '@refinio/one.models/lib/recipes/Certificates/RightToDeclareTrustedKeysForSelfCertificate.js';
import '@refinio/one.core/lib/version-map-query.js';

import Ui from './Ui.js';
import {generateDynamicManifest} from '@/utils/manifest.js';
import {loadConfig} from './config.js';
import {initI18n} from './i18n.js';
import {
    enableLogging,
    enableLogLevel,
    hasSettings,
    loadSettings
} from '@refinio/one.ui/lib/ui/views/debug/log/loggerSettings.js';

import Model, {setPandorasModel} from '@/edda/model/Model.js';
import './startEdda.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4473C5'
        },
        background: {
            default: '#f8fafd'
        }
    },
    typography: {
        fontFamily:
            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, ' +
            'Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
    }
});

/**
 * Starts the whole application.
 */
export async function startEdda(): Promise<void> {
    await navigator.storage.persist();

    await generateDynamicManifest('/manifest.json');

    await initI18n();

    // Load the configuration
    const config = await loadConfig('/config.json');

    // Configure logging
    if (await hasSettings()) {
        await loadSettings();
    } else if (config.enableLogging) {
        enableLogging(true, true);
        for (const logType of config.logTypes || ['error', 'alert', 'log']) {
            enableLogLevel(logType);
        }
    }

    try {
        await objectEvents.loadSettings();
    } catch (_e) {
        // ignore - no settings stored
    }

    // Instantiate the models
    const model = new Model(
        config.commServerUrl,
        config.researcherPublicSignKey,
        config.initialIopPeers
    );
    setPandorasModel(model);

    // Debug hack to disable connections by default at startup
    if (window.location.hash === '#cd' || window.location.hash === '#connection_disable') {
        console.log('Disable new connections mode on', window.location.hash);
        model.connections.newRoutesEnabled = false;
    }

    if (await model.one.isRegistered()) {
        await model.one.login().catch(console.error);
    }

    const rootElement = document.getElementById('root');
    if (rootElement) {
        const root = ReactDOM.createRoot(rootElement);

        root.render(
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Ui model={model} app={config.app} />
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }
}

startEdda().catch(err => console.error(err));
