import type {Person} from '@refinio/one.core/lib/recipes.js';
import type {HexString} from '@refinio/one.core/lib/util/arraybuffer-to-and-from-hex-string.js';
import type {SHA256IdHash} from '@refinio/one.core/lib/util/type-checks.js';
import BodyTemperatureModel from '@refinio/one.models/lib/models/BodyTemperatureModel.js';
import DiaryModel from '@refinio/one.models/lib/models/DiaryModel.js';
import DocumentModel from '@refinio/one.models/lib/models/DocumentModel.js';
import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import QuestionnaireModel from '@refinio/one.models/lib/models/QuestionnaireModel.js';
import WbcDiffModel from '@refinio/one.models/lib/models/WbcDiffModel.js';

import {shareHash, shareIdHash} from '@/utils/Utils.js';
import {calculateIdHashOfObj} from '@refinio/one.core/lib/util/object';

/**
 * The researcher class.
 * This class contains methods to check the researcher identity and to share data with the researcher.
 */
export default class Researcher {
    private leuteModel: LeuteModel;
    private isRootResearcher: boolean = false;
    private researcherPublicSignKey: HexString | undefined;
    private researcherId: SHA256IdHash<Person> | undefined = undefined;

    /**
     * Constructor for the researcher.
     * @param researcherPublicSignKey - the public sign key of the researcher
     * @param leuteModel - the leute model
     */
    constructor(researcherPublicSignKey: HexString | undefined, leuteModel: LeuteModel) {
        this.leuteModel = leuteModel;
        this.researcherPublicSignKey = researcherPublicSignKey;
    }

    /**
     * Initialize the researcher.
     * @returns void
     */
    public async init(): Promise<void> {
        if (this.researcherPublicSignKey === undefined) {
            return;
        }
        for (const identity of (await this.leuteModel.me()).identities()) {
            for (const keys of await this.leuteModel.trust.getKeysForPerson(identity)) {
                if (keys.trustInfo.key === this.researcherPublicSignKey) {
                    this.isRootResearcher = true;
                    this.researcherId = identity;
                }
            }
        }
    }

    /**
     * Get the researcher id.
     * @returns the researcher id
     */
    public async getResearcherId(): Promise<SHA256IdHash<Person> | undefined> {
        if (this.researcherId === undefined && this.researcherPublicSignKey !== undefined) {
            const everyone = [...(await this.leuteModel.others()), await this.leuteModel.me()];
            for (const someone of everyone) {
                for (const identity of someone.identities()) {
                    for (const keys of await this.leuteModel.trust.getKeysForPerson(identity)) {
                        if (keys.trustInfo.key === this.researcherPublicSignKey) {
                            this.researcherId = identity;
                            break;
                        }
                    }
                }
            }
        }

        return this.researcherId;
    }

    /**
     * Is the current user a researcher?
     * @returns true if the current user is a researcher, false otherwise
     */
    public amIResearcher(): boolean {
        return this.isRootResearcher;
    }

    /**
     * Share data with the researcher.
     * Used by doctors to share data with the researcher.
     * @throws if the researcher is not set
     * @throws if the researcher is the same as the doctor
     * @returns data shared status
     */
    public async shareDataWithResearcher(): Promise<boolean> {
        if (this.isRootResearcher) {
            throw new Error('Can not share data with yourself');
        }
        if (this.researcherPublicSignKey === undefined) {
            return false;
        }
        const researcherId = await this.getResearcherId();
        if (researcherId === undefined) {
            throw new Error('Researcher not found');
        }

        const someoneModels = [...(await this.leuteModel.others())];
        const mainIdentity = await this.leuteModel.myMainIdentity();

        for (const someone of someoneModels) {
            for (const profile of await someone.profiles()) {
                let isPatient = false;
                for (const certificate of await this.leuteModel.trust.getCertificates(
                    profile.personId
                )) {
                    if (
                        certificate.certificate.$type$ === 'SignupCertificate' &&
                        (await this.leuteModel.trust.isSignedBy(
                            certificate.certificateHash,
                            mainIdentity
                        ))
                    ) {
                        isPatient = true;
                        await shareHash(researcherId, certificate.certificateHash);
                        await shareHash(researcherId, certificate.signatureHash);
                        break;
                    }
                }
                if (isPatient) {
                    await shareIdHash(researcherId, profile.idHash);
                    await shareIdHash(
                        researcherId,
                        await calculateIdHashOfObj({
                            $type$: 'ChannelInfo',
                            id: QuestionnaireModel.channelId,
                            owner: profile.personId
                        })
                    );
                    await shareIdHash(
                        researcherId,
                        await calculateIdHashOfObj({
                            $type$: 'ChannelInfo',
                            id: BodyTemperatureModel.channelId,
                            owner: profile.personId
                        })
                    );
                    await shareIdHash(
                        researcherId,
                        await calculateIdHashOfObj({
                            $type$: 'ChannelInfo',
                            id: DiaryModel.channelId,
                            owner: profile.personId
                        })
                    );
                    await shareIdHash(
                        researcherId,
                        await calculateIdHashOfObj({
                            $type$: 'ChannelInfo',
                            id: WbcDiffModel.channelId,
                            owner: profile.personId
                        })
                    );
                    await shareIdHash(
                        researcherId,
                        await calculateIdHashOfObj({
                            $type$: 'ChannelInfo',
                            id: DocumentModel.channelId,
                            owner: profile.personId
                        })
                    );
                    await shareIdHash(
                        researcherId,
                        await calculateIdHashOfObj({
                            $type$: 'ChannelInfo',
                            id: 'consentFile',
                            owner: profile.personId
                        })
                    );
                }
            }
        }

        return true;
    }
}
