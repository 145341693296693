import type {ReactElement} from 'react';
import {useContext} from 'react';

import NavigationDrawer from '@refinio/one.ui/lib/ui/components/menu/NavigationDrawer.js';

import {AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar.js';
import {getEddaMenuItems} from './MenuItems.js';
import {getLeuteDebugMenuItems} from '@/components/menu/DebugMenuItems.js';

/**
 * Builds the left menu
 */
export default function Menu(props: {isResearcher?: boolean}): ReactElement {
    const {contextValue, setContextValue} = useContext(AppBarContext);

    return (
        <NavigationDrawer
            items={getEddaMenuItems(props.isResearcher)}
            open={contextValue.isLeftBtnClicked}
            debugItems={getLeuteDebugMenuItems}
            onClose={() => {
                setContextValue({
                    ...contextValue,
                    isLeftBtnClicked: false,
                    isRightBtnClicked: false
                });
            }}
        />
    );
}
