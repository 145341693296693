import {useContext, useEffect, useState, type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import {storeUnversionedObject} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import type {ChatRequest, Topic} from '@refinio/one.models/lib/recipes/ChatRecipes.js';

import {useNavigateBack} from '@/hooks/navigation.js';
import {CHAT_REQUEST_RESEARCHER_DATA} from '@/root/chat/attachmentViews/types.js';
import SelectableTopicList from '@/components/shareJournalEntryPopup/SelectableTopicList';
import {APP_BAR_MODE, AppBarContext} from '@refinio/one.ui/lib/ui/components/appBar/AppBar';

async function onDone(
    goBack: () => void,
    shareWithTopics: Topic[],
    topicModel: TopicModel
): Promise<void> {
    const dataRequest: ChatRequest = {
        $type$: 'ChatRequest',
        for: CHAT_REQUEST_RESEARCHER_DATA
    };
    const dataRequestObject = await storeUnversionedObject(dataRequest);

    for (const topic of shareWithTopics) {
        const topicRoom = await topicModel.enterTopicRoom(topic.id);
        await topicRoom.sendMessageWithAttachmentAsHash(
            '',
            [dataRequestObject.hash],
            undefined,
            null
        );
    }
    goBack();
}

export default function ResearcherDataRequestView(props: {
    topicModel: TopicModel;
    leuteModel: LeuteModel;
}): ReactElement {
    const appBarContext = useContext(AppBarContext);
    const [shareWithTopics, setShareWithTopics] = useState<Topic[]>([]);
    const i18n = useTranslation();
    const goBack = useNavigateBack();

    useEffect(() => {
        const old = appBarContext.contextValue;
        return () => {
            appBarContext.setContextValue({...old, hide: false});
        };
    }, []);

    useEffect(() => {
        appBarContext.setContextValue({
            ...appBarContext.contextValue,
            hide: false,
            mode: APP_BAR_MODE.EDIT,
            title: i18n.t('edda_menu.researcherDataRequest'),
            leftBtnCallback: goBack,
            rightBtnCallback: () => onDone(goBack, shareWithTopics, props.topicModel)
        });
    }, [goBack, shareWithTopics, props.topicModel]);

    return (
        <SelectableTopicList
            leuteModel={props.leuteModel}
            topicModel={props.topicModel}
            onChange={selected => setShareWithTopics(selected)}
            disableGroups={true}
        />
    );
}
