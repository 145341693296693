/**
 * This file provides i18n functionality.
 *
 * Note that if nobody includes this header, then i18n will not be initialized => useTranslate()
 * won't work! We should always initialize the language!
 */
import type {i18n} from 'i18next';
import {createInstance} from 'i18next';
import {getI18n, initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initI18n as oneuiInitI18n} from '@refinio/one.ui/lib/ui/i18n.js';

import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import type Locale from 'date-fns';

import {translations as leute_translations} from '@/i18n.js';

import edda_errors_en from '@/locales/edda/en/edda_errors.json';
import edda_onboarding_en from '@/locales/edda/en/edda_onboarding.json';
import edda_menu_en from '@/locales/edda/en/edda_menu.json';

import edda_errors_de from '@/locales/edda/de/edda_errors.json';
import edda_onboarding_de from '@/locales/edda/de/edda_onboarding.json';
import edda_menu_de from '@/locales/edda/de/edda_menu.json';

const i18nNamespace = 'leute';

export const translations: Record<string, Record<string, any>> = {
    en: {
        ...leute_translations.en,
        edda_errors: edda_errors_en,
        edda_onboarding: edda_onboarding_en,
        edda_menu: edda_menu_en
    },
    de: {
        ...leute_translations.de,
        edda_errors: edda_errors_de,
        edda_onboarding: edda_onboarding_de,
        edda_menu: edda_menu_de
    }
};

export const availableLanguages = ['de', 'en'];

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

export async function initI18n(
    options: {
        i18nInstance?: i18n;
        registerResources: boolean;
    } = {registerResources: true}
): Promise<void> {
    if (options.i18nInstance === undefined) {
        options.i18nInstance = createInstance({
            returnNull: false
        });
        options.i18nInstance.use(initReactI18next);
        options.i18nInstance.use(LanguageDetector);
        await options.i18nInstance.init({
            defaultNS: i18nNamespace,
            fallbackLng: availableLanguages[0],
            supportedLngs: availableLanguages
        });
    }

    if (options.registerResources) {
        for (const lang in translations) {
            options.i18nInstance.addResourceBundle(
                lang,
                i18nNamespace,
                translations[lang],
                true,
                true
            );
        }
    }

    await oneuiInitI18n({i18nInstance: options.i18nInstance, registerResources: true});
}

/**
 * Wrapper for solve some i18next issues
 *
 * This wrapper will always access the i18n instance currently used by the i18n-react library
 * We cannot export the i18n instance created by initI18n, because this does not change when using
 * i18n provider.
 *
 * add more functionality as needed.
 */
class I18nWrapper {
    get i18n(): i18n {
        return getI18n();
    }

    t(key: string): string {
        return this.i18n.t(key);
    }

    async changeLanguage(lng: string): Promise<void> {
        await this.i18n.changeLanguage(lng);
    }

    getLocale(language?: string): Locale {
        const lang = language ? language : this.i18n.language;
        if (lang === 'en') {
            return enLocale;
        }
        return deLocale;
    }
}

export default new I18nWrapper();
